import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class ErrorService implements ErrorHandler {
    constructor(private injector: Injector, private zone: NgZone) { }

    handleError(error: HttpErrorResponse) {
        const router = this.injector.get(Router);

        switch (error.status) {
            case 0: //UNKNOWN
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
            case 400: //BAD REQUEST
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
            case 401: //UNAUTHORIZED
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
            case 403: //FORBIDDEN
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
            case 404: //NOT FOUND
                this.zone.run(() => {router.navigate(['/error-empty-state'])});
                break;
            case 500: //INTERNAL SERVER ERROR
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
            case 503: //SERVICE UNAVAILABLE
                this.zone.run(() => {router.navigate(['/error-system-failure'])});
                break;
        }
    }
}
