<div class="container d-block mb-5">
    <div class="d-flex mt-md-5 flex-md-row flex-column align-items-center">
        <img class="col-md-6 col-12" [src]="'../../../../assets/images/otp/otp-empty.svg'" [alt]="'otp-empty'" />
        <div class="d-flex offset-md-1 col-md-5 col-12 flex-column" *ngIf="!showVerifyBtn">
            <h1>Please request a verification code</h1>
            <p>
                We will send an email to your registered email with your code
            </p>

            <br>
            <lib-button
                [buttonSize]="'large'"
                (callback)="sendOtp()"
                [buttonTheme]="'primary'"
                [buttonText]="'Send code'"
            >
            </lib-button>
        </div>
        <div class="d-flex offset-md-1 col-md-5 col-12 flex-column" *ngIf="showVerifyBtn">
            <h1>Please verify your code</h1>
            <p>
                We sent an email to <span style="font-weight: 700">{{ edeliveryOrchestrationResponse?.email }}</span>
                with your code.
            </p>

            <form [formGroup]="validate">
                <div class="relative">
                    <lib-input-text [field]="code" [formControlName]="code.name"></lib-input-text>
                    <small class="bottom" *ngIf="!validate.controls.code.touched">
                        The code is only valid for 15 minutes.
                    </small>
                </div>
            </form>


            <lib-button
                [buttonSize]="'large'"
                (callback)="verifyOtp()"
                [disabled]="!validate.valid"
                [buttonTheme]="'primary'"
                [buttonText]="'Verify'"
            >
            </lib-button>

            <small class="psic-gap-3">Still have not received the email?

                <a class="link caption-bold"  (click)="resendCode()">Re-send code</a
                >
            </small
            >
        </div>
    </div>
</div>
