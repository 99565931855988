import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { PopupMessageComponent } from '../../components/popup-message/popup-message.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
   
    constructor(private bsModalService: BsModalService) {}
    bsModalRef!: BsModalRef;
    confirm(popupDispalyObj: any, className?: string) {
              const initialState :any= {
            popupDispalyObj: popupDispalyObj,
           
        };   
        const config ={
            backdrop: true,
            ignoreBackdropClick: true
        }     
        this.bsModalRef = this.bsModalService.show(PopupMessageComponent, Object.assign({}, config, {initialState}) );
        if (className) {
                       this.bsModalRef.setClass(className);
        }
        return new Observable<string[]>(this.getConfirmSubscriber());
    }

    private getConfirmSubscriber() {
        return (observer: any) => {
            const subscription = this.bsModalService.onHidden.subscribe((reason: string[]) => {
                observer.next(this.bsModalRef.content.popupCallbackDetail);
                observer.complete();
            });

            return {
                unsubscribe() {
                    subscription.unsubscribe();
                },
            };
        };
    }
}
