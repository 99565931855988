import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading/loading.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit, AfterViewInit, OnDestroy {
    loading = false;
    loadingSubscription = new Subscription();
    loadingTemplate: TemplateRef<any>;

    @ViewChild('spinnerTemplate', { static: false }) spinnerTemplate: TemplateRef<any>;

    constructor(private loadingService: LoadingService) {}

    ngOnInit() {}

    ngOnDestroy() {
        this.loadingSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.loadingService.setDefaultTemplate(this.spinnerTemplate);

        this.loadingSubscription.add(
            this.loadingService.getLoadingStatus().subscribe((value) => {
                this.loading = value;
            })
        );

        this.loadingSubscription.add(
            this.loadingService.loadingTemplate$.subscribe((template: any) => {
                this.loadingTemplate = template;
            })
        );
    }
}
