import { Component, OnInit, Input, HostListener } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  FieldControlService,
  IField,
} from '@pacificspecialtyinsurancecompany/psic-ng-components';

@Component({
  selector: 'app-popup-message',
  templateUrl: './popup-message.component.html',
  providers: [FieldControlService],
})
export class PopupMessageComponent implements OnInit {
  @Input() popupDispalyObj: any;
  @Input() form!: FormGroup;
  isMobileWidth: boolean;
  popupCallbackDetail: string[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private fieldService: FieldControlService
  ) {
    this.getWidthOfScreenSize();
  }

  ngOnInit(): void {
    this.form = this.fieldService.toFormGroup(
      this.popupDispalyObj.fields as IField[]
    );
  }
  @HostListener('window:resize', ['$event'])
  getWidthOfScreenSize() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 700) {
      this.isMobileWidth = true;
    } else {
      this.isMobileWidth = false;
    }
  }

  footerButtonAction(event: any) {
    if (event == 'Verify' && this.form.controls.otp.value) {
      this.popupCallbackDetail.push(event, this.form.controls.otp.value);
      this.bsModalRef.hide();
    }
  }
}
