import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { ErrorSystemFailureComponent } from './error-system-failure/error-system-failure.component';
import { OTPAcceptComponent } from './otp/accept-confirmation/accept-confirmation.component';
import { OTPComponent } from './otp/otp.component';
import { OTPSignComponent } from './otp/sign-application/sign-application.component';

const routes: Routes = [
    { path: '', component: OTPComponent },
    { path: 'sign-verification', component: OTPSignComponent },
    { path: 'accept-confirmation', component: OTPAcceptComponent },
    { path: 'document-list', component: DocumentsListComponent, data: { state: 'DocumentDownload' } },
    { path: 'error-system-failure', component: ErrorSystemFailureComponent },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class PagesRoutingModule {}
