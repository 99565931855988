import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, ToastService } from 'src/app/shared/services';

@Component({
    selector: 'app-otp-sign',
    templateUrl: './sign-application.component.html',
    styleUrls: ['./sign-application.component.scss'],
})
export class OTPSignComponent {
    data: any;
    policyNo: any;
    documents: any[];

    constructor(
        private router: Router,
        private apiService: ApiService,
        private toastServicee: ToastService
    ) {
        this._getSessionData();
    }

    private _getSessionData() {
        // this.route.queryParams.subscribe((params) => {
        //     if (params && params['policyNo']) {
        //         this.data = params;
        //     }
        // });
        this.data = this.router.getCurrentNavigation()?.extras.state?.data;
        this.policyNo = this.router.getCurrentNavigation()?.extras.state?.policyNo;
        this.documents = this.data.document;
    }

    acceptApplication(): void {
        this.apiService.acceptEsign(this.policyNo).subscribe(
            (data) => {
                if (data.status == 'Success') {
                    this.router.navigate(['/accept-confirmation']);
                    // this.router.navigate(['/accept-confirmation'], {
                    //     queryParams: this.data,
                    // });
                } else {
                    this.toastServicee.add({
                        type: 'error',
                        title: '',
                        message: 'Technical Difficulties.',
                    });
                }
            },
            (_) => {
                this.toastServicee.add({
                    type: 'error',
                    title: '',
                    message: 'Technical Difficulties.',
                });
            }
        );
    }

    downloadApplication(){
        window.open(this.documents[0].link, '_blank');
    }

    // downloadApplication() {
    //     let downloadDocPayload = {
    //         policyorquotenumber: this.data['policyNo'],
    //         doctypes: 'Application',
    //         downloadall: 'no',
    //     };
    //     this.apiService.downloadDocument(downloadDocPayload).subscribe(
    //         (data: any) => {
    //             if (data.statusCode == 200) {
    //                 var a = document.createElement('a');
    //                 a.href = data.link;

    //                 // start download
    //                 a.click();

    //                 //show sucess message once file downloaded sucessfully
    //                 this.toastServicee.add({
    //                     type: 'success',
    //                     title: '',
    //                     message: 'Documents downloaded successfully.',
    //                 });
    //             }
    //             //  this.loadingService.hideLoading();
    //         },
    //         (error: any) => {
    //             // this.loadingService.hideLoading();
    //         }
    //     );
    // }
}
