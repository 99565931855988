import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';

import { FieldControlService, PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';

import { environment } from 'src/environments/environment';

import {
  AbstractService,
  ErrorService,
  DataMockWebService,
  ApiService,
} from './shared/services';

import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    PSICComponentsModule,
    NoopAnimationsModule,
    ModalModule.forRoot(),
    PagesModule,
    SharedModule,
  ],
  providers: [
    FieldControlService,
    {
      provide: AbstractService,
      useFactory: resolveService,
      deps: [HttpClient, ErrorService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function resolveService(http: HttpClient, errorService: ErrorService) {
  return environment.useMock
    ? new DataMockWebService(http)
    : new ApiService(http, errorService);
}
