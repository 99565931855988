import { Injectable, TemplateRef } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    private loading: BehaviorSubject<boolean>;
    private loadingTemplateSubject: BehaviorSubject<TemplateRef<any>>;
    defaultTemplateRef: TemplateRef<any>;
    loadingTemplate$: Observable<TemplateRef<any>>;

    constructor() {
        this.loading = new BehaviorSubject<boolean>(false);
    }

    getTemplate(): Observable<TemplateRef<any>> {
        return this.loadingTemplateSubject.asObservable();
    }

    setDefaultTemplate(newValue: TemplateRef<any>): void {
        this.defaultTemplateRef = newValue;

        if (this.loadingTemplateSubject == null) {
            this.loadingTemplateSubject = new BehaviorSubject<TemplateRef<any>>(this.defaultTemplateRef);
            this.loadingTemplate$ = this.loadingTemplateSubject.asObservable();
        }

        this.loadingTemplateSubject.next(this.defaultTemplateRef);
    }

    setTemplate(newValue: TemplateRef<any>): void {
        this.loadingTemplateSubject.next(newValue);
    }

    resetTemplate() {
        this.loadingTemplateSubject.next(this.defaultTemplateRef);
    }

    getLoadingStatus(): Observable<boolean> {
        return this.loading.asObservable();
    }

    setLoadingStatus(newValue: boolean): void {
        this.loading.next(newValue);
    }

    showLoading(): void {
        this.setLoadingStatus(true);
    }

    hideLoading(): void {
        this.setLoadingStatus(false);
    }
}
