import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Field, FieldControlService } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { ApiService, SessionService, LoadingService, ToastService } from 'src/app/shared/services';


@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.scss'],
})
export class OTPComponent implements OnInit {
    title = 'phoenix-document-delivery';
    policyNo: any;
    sessionId: string;
    edeliveryorchestrationdata: any;
    email: any;
    disableLink = false;
    showVerifyBtn = false;

    docDownloadObj = {
        type: 'actionDialog',
        download: false,
        summary: true,
        imageSrc: '../../../../../assets/images/tick-img.svg',
        message: 'OTP sent successfully',
        confirm: 'Verify',
        cancel: 'Resend OTP',
        fields: [
            {
                type: 'number',
                caption: 'OTP',
                name: 'otp',
                placeholder: 'Enter OTP',
                required: true,
                value: null,
                id: 'OTP',
                helpText: '',
            },
        ],
    };

    code = new Field({
        name: 'code',
        helpIcon: false,
        caption: 'Enter Code',
        id: 'code',
        placeholder: '',
        type: 'text',
        keyboardFilter: '[0-9]+',
        maxLength: 7,
        required: true,
        additionalContext: {
            customValidation: {
                validators: [Validators.pattern(/[0-9]{7}/)],
            },
        },
        validationMessages: [
            {
                type: 'invalid',
                message: 'The code is invalid. Please try resending it.',
            },
            {
                type: 'expired',
                message: 'The code you entered has expired. Please resend it.',
            },
            {
                type: 'required',
                message: 'This field is required.',
            },
        ],
    });

    validate: FormGroup = this.fieldService.toFormGroup([this.code]);
    edeliveryOrchestrationResponse: {
        statusCode: number;
        statusMessage: string;
        sessionId: string;
        documents: string[];
        ESignFlag: boolean;
        email: string;
        queryResponse: any;
    };

    constructor(
        private fieldService: FieldControlService,
        private apiService: ApiService,
        private sessionService: SessionService,
        private route: ActivatedRoute,
        private router: Router,
        private toastService: ToastService,
        private loadingService: LoadingService
    ) {}

    ngOnInit(): void {
        //this.loadingService.showLoading();
        this._setSessionId();
        //this.edeliveryOrchestrationResponse.email= "sboddupally@gmail.com";
       
    }


    private _setSessionId() {
        //Get the session ID from url after redirection from mail
        this.route.queryParams.subscribe((params) => {
            console.log(params); // { orderby: "price" }
            if (params && params['sessionId']) {
                this.email = params.email;
                this.sessionService.sessionId = params['sessionId'];
                this.policyNo = params['PolicyNumber'];
                //Need to call service to get the user details based on session Id
                this.edeliveryorchestrationdata = {
                    sessionId: params.sessionId,
                };
            }
        });
    }

    sendOtp() {
        console.log("edeliveryorchestrationdata: "+this.edeliveryorchestrationdata);
        this.showVerifyBtn = true;
        this.apiService.sendOTP(this.edeliveryorchestrationdata).subscribe(
            (data: any) => {
                this.edeliveryOrchestrationResponse = data;
                this.showVerifyBtn = true;
            },
            (error: any) => {
                this.router.navigate(['error-system-failure']);
                this.loadingService.hideLoading();
            }
        );
    }

    verifyOtp() {
        let a = {
            action: 'verifyOTP',
            sessionId: this.edeliveryOrchestrationResponse.sessionId,
            OTP: this.validate.controls.code.value,
            requestType: "documentDelivery",
            documentSessionId: this.edeliveryorchestrationdata.sessionId
        };

        this.apiService.verifyOTP(a).subscribe((data: any) => {
            if (data.statusMessage == 'FAILED') {
                this.validate.controls.code.setErrors({ invalid: true });
            }

            if (data.statusMessage == 'EXPIRED') {
                this.validate.controls.code.setErrors({ expired: true });
            }

            if (data.statusMessage == 'SUCCESS') {
                // If esign flag is true goto esign flow else goto documents flow
                if (this.edeliveryOrchestrationResponse.ESignFlag) {
                    // this.router.navigate(['/sign-verification'], {
                    //     queryParams: {
                    //         policyNo: this.policyNo,
                    //         documents: this.edeliveryOrchestrationResponse.documents,
                    //     },
                    // });
                    this.router.navigate(['/sign-verification'], {state: {data : data, policyNo: this.policyNo}});
                } else {
                    // if(this.edeliveryOrchestrationResponse.documents != null){
                    //     this.router.navigate(['/document-list'], {
                    //         queryParams: {
                    //             policyNo: this.policyNo,
                    //             documents: this.edeliveryOrchestrationResponse.documents,
                    //         },
                    //     });
                    // }else{
                    //     this.router.navigate(['/document-list'], {
                    //         queryParams: {
                    //             policyNo: this.policyNo,
                    //             queryResponse: JSON.stringify(this.edeliveryOrchestrationResponse.queryResponse),
                    //         }
                    //     });
                    // }  
                    this.router.navigate(['/document-list'], {state: {data : data}});                  
                }
            }
        });
    }

    resendCode() {
        this.apiService.resendOTP(this.edeliveryorchestrationdata).subscribe((data: any) => {
            this.edeliveryOrchestrationResponse = data;
            this.toastService.add({
                type: 'success',
                title: '',
                message: 'Please check your email, code has been resent.',
            });
        });
    }
}
