import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IResponse, Response } from '../../interfaces';

@Injectable({
    providedIn: 'root',
})
export abstract class AbstractService {
    abstract setData(response: IResponse): Observable<IResponse>;

    getMockResponse(stepName: string): Observable<IResponse> {
        return of(new Response());
    }
}
