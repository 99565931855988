import { IPage, Page } from "../../../core/page";

export interface IResponse {
    data: IPage;
    baseData: any;
}
export class Response implements IResponse {
    data: IPage;
    baseData: any;

    constructor(a: Partial<Response> = {}) {
        this.baseData = a.baseData || '';
        this.data = new Page(a.data);
    }
}
