<div class="container d-block mt-5 mb-5">
    <div class="d-flex mt-md-5 flex-md-row flex-column align-items-center">
        <img class="col-md-6 col-12" [src]="'../../../../assets/images/otp/otp-accept.svg'" [alt]="'otp-accept'" />
        <div class="d-flex col-md-5 offset-md-1 col-12 justify-content-center flex-column">
            <h1>Congrats!</h1>
            <p>Your application is ready for our final review. We'll keep you updated.</p>

            <!-- <lib-button
                [btnSize]="'large'"
                [disabled]="!data"
                (callback)="showDocuments()"
                [buttonTypeData]="'primary'"
                [btnText]="'See my documents'"
            >
            </lib-button> -->
        </div>
    </div>
</div>
