<ng-container *ngIf="page$ | async as page">
  <app-route-page
    [intro]="page.getFieldValue('heading')"
    [introSub]="page.getFieldValue('subText')"
  >
    <div
      class="
        d-flex
        flex-column flex-md-row
        gap-4
        mt-5
        align-items-stretch
        justify-content-center
        flex-md-wrap
        desktop-view
      "
    >
      <ng-container *ngFor="let option of docObj.options.optionsList">
        <app-discount-card
          psicTooltipPlacement="top"
          [psicTooltipDisabled]="false"
          psicTooltip="{{option.caption}}"
          [title]="option.caption"
          [iconSource]="option.icon_path"
          [displayCheckboxForMobile]="true"
          [value]="option.value"
          [defaultValues]="docObj.value"
          (performAction)="makeAction($event, docObj)"
          (selectedChanged)="chooseCard($event, option, docObj)"
          [eSignFlag]="eSignFlag"
        >
        </app-discount-card>
      </ng-container>
    </div>
    <div
      class="justify-content-center gap-md-5"
      [ngClass]="{ 'd-flex flex-row-reverse mt-5': !isMobileWidth }"
    >
      <div
        class="d-flex flex-row justify-content-center"
        [ngClass]="{ 'mt-5': isMobileWidth }"
      >
        <lib-button
          (callback)="docDownload('docDownload', page!)"
          [disabled]="!(docObj!.value.length > 0)"
          [buttonTheme]="'primary'"
          [buttonSize]="'small'"
          [buttonText]="'Download documents'"
        ></lib-button>
      </div>
    </div>
  </app-route-page>
</ng-container>
