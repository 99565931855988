import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { IPage, Page } from 'src/app/core/page';

import { Response, IResponse } from '../../interfaces';
import { AbstractService } from '../abstract/abstract.service';

@Injectable({
  providedIn: 'root',
})
export class DataMockWebService extends AbstractService {
  constructor(private http: HttpClient) {
    super();
  }

  getMockServerMap(): Observable<any> {
    const url = `../../assets/local_data/DocumentDownload.json`;

    return this.http.get<any>(url);
  }

  firstGet = true;

  setData(response: IResponse): Observable<IResponse> {
    const stepName = response.data.name;
    return this.getMockServerMap().pipe(
      switchMap((mockData) => {
        const nextMap = new Map(Object.entries(mockData));
        let nextStep = nextMap.get(stepName) as string;
        if (stepName == 'zip-code' && this.firstGet) {
          nextStep = 'zip-code';
          this.firstGet = false;
        }
        const jsonFormattedName = nextStep.split('-').join('_');
        const url = `../../../assets/local_data/${jsonFormattedName}.json`;

        return this.http.get<IPage>(url).pipe(
          map(
            (data: any) =>
              new Response({
                data: new Page(data.data),
                baseData: data.baseData,
              })
          ),
          delay(2000)
        );
      })
    );
  }

  getMockResponse(stepName: string): Observable<IResponse> {
    const jsonFormattedName = stepName.split('-').join('_');
    const url = `../../../assets/local_data/${jsonFormattedName}.json`;

    return this.http
      .get<IResponse>(url)
      .pipe(map((data: any) => new Response(data)));
  }
}
