<div class="container d-block mb-5">
    <div class="d-flex mt-md-5 align-items-center flex-md-row flex-column">
        <img class="col-md-6 col-12" [src]="'../../../../assets/images/otp/otp-sign.svg'" [alt]="'otp-sign'" />
        <div class="d-flex col-md-5 offset-md-1 col-12 justify-content-center flex-column">
            <h1>Let's accept your application!</h1>
            <p>Your policy will get canceled if it is not accepted within 25 days after the policy effective date.</p>

            <p class="caption mt-4">
                Please
                <a [routerLink]="" class="caption-bold" style="color: #0a58ca" (click)="downloadApplication()"
                    >click here</a
                >
                to download & review the Application and Disclosures.
            </p>

            <p class="caption mt-4">
                By clicking 'Accept' I agree with all the information represented in the Application and Disclosures
                including the unacceptable risks and policy binding procedures.
            </p>

            <lib-button
                [buttonSize]="'large'"
                [disabled]="!data"
                (callback)="acceptApplication()"
                [buttonTheme]="'primary'"
                [buttonText]="'Accept'"
            >
            </lib-button>
        </div>
    </div>
</div>
