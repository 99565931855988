import { Component } from '@angular/core';

@Component({
    selector: 'app-otp-accept',
    templateUrl: './accept-confirmation.component.html',
    styleUrls: ['./accept-confirmation.component.scss'],
})
export class OTPAcceptComponent {
    //data: any;

    constructor(){}

    // constructor(private router: Router, private route: ActivatedRoute) {
    //     this._getSessionData();
    // }

    // private _getSessionData(): void {
    //     this.route.queryParams.subscribe((params) => {
    //         if (params && params['policyNo']) {
    //             this.data = params;
    //         }
    //     });
    // }

    // showDocuments() {
    //     this.router.navigate(['/document-list'], {
    //         queryParams: this.data,
    //     });
    // }
}
