import {IResponse} from "../shared/interfaces/response/response.interface";
import {BehaviorSubject} from "rxjs";
import {IPage, Page} from "./page";

export class CacheData {
  pageSnapshot: IPage;
  response: IResponse;
  pageSubject = new BehaviorSubject<IPage>(new Page());
  page$ = this.pageSubject.asObservable();

  constructor(response: IResponse) {
    this.response = response;
    this.takeSnapshot(this.response.data);
    this.pageSubject.next(response.data);
  }

  takeSnapshot(page: IPage): void {
    this.pageSnapshot = new Page(page);
    this.setPageModified(false);
  }
  setPageModified(modified: boolean) : void {
    if(this.response.baseData) {
      this.response.baseData.pageModified = modified;
    } else {
      this.response.baseData = {pageModified: modified};
    }
  }

  prepare(updatedPage: IPage): void {
    this.response.data = updatedPage;
     if(JSON.stringify(updatedPage) != JSON.stringify(this.pageSnapshot)) {
       console.log(updatedPage.name + ' is modified');
       this.setPageModified(true);
     } else {
       console.log(updatedPage.name + ' is NOT modified');
     }
  }

  updateResponse(res: IResponse): void {
    this.response = res;
    this.response.baseData.pageModified = false;
  }
}
