import { Field, IField } from '@pacificspecialtyinsurancecompany/psic-ng-components';

export interface IPage {
    readonly: boolean;
    page: string;
    name: string;
    url: string;
    fields: Array<IField>;
    getFieldValue(name: string): any;
    getField(name: string): IField | undefined;
}

export class Page implements IPage {
    fields = new Array<IField>();
    readonly: boolean;
    url: string;
    page: string;
    name: string;
    constructor(a: Partial<Page> = {}) {
        this.readonly = a.readonly || false;
        this.url = a.url || '';
        this.page = a.page || '';
        this.name = a.name || '';
        if (a.fields) {
            a.fields.forEach((f) => this.fields.push(new Field(f)));
        }
    }

    getFieldValue(name: string): any {
        const f = this.fields.find((obj) => obj.name == name);
        if (f) {
            return f.value;
        }
        return `${name} not found`;
    }
    showWarn = true;
    getField(name: string): IField | undefined {
        const f = this.fields.find((fieldObj) => fieldObj.name == name);
        if (f) {
            return f;
        }
        if (this.showWarn) {
            this.showWarn = false;
            console.warn(`${name} not found`);
        }
        return undefined;
    }
}
