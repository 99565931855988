import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
    selector: 'app-selectable-card',
    templateUrl: './selectable-card.component.html',
    styleUrls: ['./selectable-card.component.scss'],
})
export class SelectableCardComponent {
    @HostBinding('attr.role') role = 'button';

    @HostBinding('class.selected')
    @Input()
    selected = false;

    @Input()
    get page(): string {
        return this.pageVal;
    }

    set page(val: string) {
        if (val) {
            this.pageVal = val;
        }
    }
    pageVal = '';

    @Input()
    get singleCard(): boolean {
        return this.isSingleCard;
    }

    set singleCard(val: boolean) {
        this.isSingleCard = true;
        if (val) {
            this.selected = false;
            this.pointer = 'none';
            this.role = 'card';
        }
    }
    isSingleCard = false;

    @Output() selectedChanged = new EventEmitter<boolean>();

    @HostBinding('class.disabled')
    @Input()
    disabled = false;

    @HostBinding('style.minHeight') minHeight = '0px';
    @HostBinding('style.pointer') pointer = 'pointer';

    constructor() {}

    selectedChanging(): void {}

    @HostListener('click', ['$event'])
    selectCard($event: MouseEvent): void {
        if (!this.isSingleCard && !this.disabled && $event.button !== 2 && this.page != 'package') {
            this.selected = !this.selected;
            this.selectedChanging();
            setTimeout(() => {
                this.selectedChanged.emit(this.selected);
            }, 20);
        }
    }
}
