<div
    *ngFor="let toast of toastService.toasts; index as i"
    class="toast d-flex flex-column align-items-start"
    [ngClass]="toast.type == 'error' ? 'error' : ''"
    style="top: 75px"
>
    <div class="d-flex flex-row notification-content">
        <div class="text-icon d-flex flex-row">
            <img
                *ngIf="toast.type == 'error'"
                src="../../../../assets/images/notification-icon-error.svg"
                alt="notification-icon-img"
            />
            <img
                *ngIf="toast.type != 'error'"
                src="../../../../assets/images/notification-icon.svg"
                alt="notification-icon-img"
            />

            <div class="d-flex text-break caption wh-100-color p-2">{{ toast.message }}</div>
        </div>
        <div class="d-flex flex-fill">
            <img src="../../../../assets/images/close-icon.svg" (click)="remove(i)" alt="close-icon-img" />
        </div>
    </div>
</div>
