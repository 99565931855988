import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { AbstractService } from '../abstract/abstract.service';
import { ErrorService } from '../error/error.service';
import { environment } from 'src/environments/environment';
import { Page } from 'src/app/core/page';
import { IResponse, Response } from '../../interfaces';

@Injectable({
    providedIn: 'root',
})
export class ApiService extends AbstractService {
    url = environment.apiUrl;
    eurl = environment.edeliveryOTP;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
        }),
    };
    constructor(private http: HttpClient, private errorService: ErrorService) {
        super();
    }

    // service to download document on "document-download" page
    downloadDocument(requestParam: any): any {
        let operationUrl = this.url + 'downloadorchestration/qnb';
        return this.http.post<any>(operationUrl, requestParam);
    }

    postData(url: string, data: any) {
        return this.http.post(url, data).pipe(retry(0));
    }

    setData(response: IResponse): Observable<IResponse> {
        const request = { baseData: response.baseData, data: response.data };

        // Set the user details
        // let user = this.userService.getUser();
        // request.baseData.userName = user.User_Name;
        // request.baseData.producerCode = user.Producer_Code;

        return this.http.post<IResponse>(this.url, request, this.httpOptions).pipe(
            tap((dataValue) => {
                console.log(dataValue);
            }),

            catchError((error: HttpErrorResponse) => {
                this.errorService.handleError(error);
                return throwError(error);
            }),

            map((obj: any) => {
                console.log(obj);
                return new Response({
                    baseData: obj.baseData,
                    data: new Page(obj.data),
                });
            })
        );
    }

    sendOTP(edeliveryorchestrationdata: any): any {
        let operationUrl = environment.edeliveryorchestration;

        return this.http.post<any>(operationUrl, edeliveryorchestrationdata);
    }

    resendOTP(edeliveryorchestrationdata: any): any {
        let operationUrl = environment.edeliveryorchestration;

        return this.http.post<any>(operationUrl, edeliveryorchestrationdata);
    }

    verifyOTP(requestParam: any): any {
        let operationUrl = this.eurl;

        return this.http.post<any>(operationUrl, requestParam);
    }

    acceptEsign(policyNo: any) {
        let request = {
            attachmentDocId: '',
            policyNo: policyNo,
            request: {
                signatureName: '',
                dateOfSign: new Date().toISOString(),
                esign: '2',
                documentCompleted: '',
                envelopeCompleted: '',
                osandBrowserInformation: '',
                landingPageVisited: '',
                documentsHashValue: '',
                afterStepCompleted: '',
                beforeStepStarted: '',
                timeDateStamps: '',
                documentStarted: '',
                envelopeStarted: '',
                ipaddresses: '',
                geoCoordinates: '',
                policyNo: policyNo,
            },
        };

        return this.http.post<any>(environment.apiUrl + 'signatureinfoupdate', request);
    }
}
