import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location } from '@angular/common';
import { IPage } from 'src/app/core/page';
import { DataMockWebService } from 'src/app/shared/services';

@Component({
    selector: 'app-error-system-failure',
    templateUrl: './error-system-failure.component.html',
    styleUrls: ['./error-system-failure.component.scss'],
})
export class ErrorSystemFailureComponent implements OnInit {
    page$: Observable<IPage>;
    errorStatus: any;
    dataStateName: string = 'ErrorSystemFailure';
    //history go back

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private dataMock: DataMockWebService
    ) {}

    ngOnInit(): void {
        this.errorStatus = this.route.snapshot.paramMap.get('errorStatus');
        if (this.errorStatus === 404) {
            this.dataStateName = 'ErrorEmptyState';
        }
        this.page$ = this.dataMock.getMockResponse(this.dataStateName).pipe(map((res) => res.data));
    }

    pageNavigation($event: any, page: IPage): void {
        if ($event === 'okGotIt' || $event === 'goBack') {
            this.location.back();
        } else {
            this.router.navigate(['address']);
        }
    }
}
