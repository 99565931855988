import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IField, Field } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { Observable } from 'rxjs';
import { IPage } from '../../core/page';
import { environment } from 'src/environments/environment';
import { DataStoreService, ApiService, ToastService } from 'src/app/shared/services';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss'],
})
export class DocumentsListComponent implements OnInit {
    page$: Observable<IPage>;
    isMobileWidth: boolean;
    eSignFlag: any;
    documents: string[];
    docObj: any = [];
    queryResponse: any[]; 
    downloadDocPayload: any;
    data: any;

    constructor(
        private toast: ToastService,
        private route: ActivatedRoute,
        private router: Router,
        private dataStore: DataStoreService,
        private apiService: ApiService
    ) {
        this.data = this.router.getCurrentNavigation()?.extras.state?.data;
        this.eSignFlag = this.data.eSignFlag;
        this.eSignFlag = this.eSignFlag === 'true' ? true : false;
        this.documents = this.data.document;
        if(this.data.queryResponse)
            this.queryResponse = JSON.parse(JSON.stringify(this.data.queryResponse));
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: any) => {
            //  route.snapshot.queryParams.documents
            this.page$ = this.dataStore.getPageData(data.state, true);
            let arr: any = [];
            if(this.documents != null){
                this.documents.forEach((val: any, key: any) => {
                    if(val.link){
                        arr.push(
                            new Field({
                                caption: val.doctypes,
                                value: val.link,
                                icon_path: 'document-download',
                                additionalContext: {
                                    policyNo: val.policyNo
                                }
                            })
                        );
                    }
                });
            } else{
                this.queryResponse.forEach((val) => {
                    if(val.link){
                        arr.push(
                            new Field({
                                caption: val.attachmentFileName,
                                value: val.link,
                                icon_path: 'document-download',
                                additionalContext: {
                                    policyNo: val.policyNo
                                }
                            })
                        );
                    }                 
                });
            }
            
            this.docObj = new Field({
                name: 'documentDownload',
                value: [],
                type: 'check_card',
                required: false,
                id: 'documentDownload',
                multiSelect: true,
                options: {
                    optionsList: arr,
                },
            });
        });
    }

    @HostListener('window:resize', ['$event'])
    getWidthOfScreenSize() {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 576) {
            this.isMobileWidth = true;
        } else {
            this.isMobileWidth = false;
        }
    }

    chooseCard(event: any, option: IField, field: IField): void {
        if (event) {
            if (!field.value.includes(option.value)) {
                this.docObj.value.push(option.value);
            }
        } else {
            this.docObj.value.splice(field.value.indexOf(option.value), 1);
        }
    }

    makeAction(obj: any, field: IField) {
        let req = {
            attachmentDocId: '',
            policyNo: field.additionalContext.policyNo,
            request: {
                signatureName: '',
                dateOfSign: new Date().toISOString(),
                esign: obj.type == 'approve' ? '2' : '-1',
                documentCompleted: '',
                envelopeCompleted: '',
                osandBrowserInformation: '',
                landingPageVisited: '',
                documentHashValue: '',
                afterStepCompleted: '',
                beforeStepStarted: '',
                timeDateStamps: '',
                documentStarted: '',
                envelopeStarted: '',
                ipaddresses: '',
                geoCoordinates: '',
                policyNo: field.additionalContext.policyNo,
            },
        };

        this.apiService.postData(environment.apiUrl + 'signatureinfoupdate', req).subscribe(
            (data: any) => {
                if (data['status'] == 'Success') {
                    this.toast.add({
                        type: 'success',
                        title: '',
                        message: 'Esgin Verified successfully.',
                    });
                } else {
                    this.toast.add({
                        type: 'error',
                        title: '',
                        message: 'Technical Difficulties.',
                    });
                }
            },
            (error: any) => {}
        );
    }

    docDownload($event: any, page: IPage): void {
        this.docObj.value.forEach((link: string) => {
            window.open(link, '_blank');
        })
    }

    // docDownload($event: any, page: IPage): void {
    //     let docTypesValues = this.docObj.value.toString();

    //     // In below payload, policyorquotenumber to be passed as function parameter later for dynamic data
    //     if(this.documents != null){
    //         this.downloadDocPayload = {
    //             policyorquotenumber: this.policyNo,
    //             doctypes: docTypesValues,
    //             downloadall: 'no',
    //         };
    //     }else{
    //         this.downloadDocPayload = {
    //             attachmentDocId: docTypesValues,
    //             downloadall: 'no',
    //         };
    //     }
        
        
    //     this.loadingService.showLoading();
    //     this.apiService.downloadDocument(this.downloadDocPayload).subscribe(
    //         (data: any) => {
    //             if (data.statusCode == 200) {
    //                 var a = document.createElement('a');
    //                 a.href = data.link;

    //                 // start download
    //                 a.click();

    //                 //show sucess message once file downloaded sucessfully
    //                 this.toast.add({
    //                     type: 'success',
    //                     title: '',
    //                     message: 'Documents downloaded successfully.',
    //                 });
    //             }
    //             this.loadingService.hideLoading();
    //         },
    //         (error: any) => {
    //             this.loadingService.hideLoading();
    //         }
    //     );
    // }

    userAction: string[] = [];
    sendEmail(event: any, page: IPage) {
        // this.modalService.confirm(this.popupDispalyObj).subscribe((popupCallbackDetail) => {
        //     this.userAction = popupCallbackDetail;
        // });
    }
}
