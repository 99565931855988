<div class="header">
    <div class="container">
        <div class="header-row justify-content-center justify-content-sm-start" [attr.dashboard]="false">
            <div class="logo-container">
                <div class="psic-logo">
                        <img src="../../../../assets/images/psic_logo.svg" alt="psic-logo-img" />
                </div>
            </div>
        </div>
    </div>
</div>


