<!--show on smaller screens-->
<div class="d-flex flex-column py-2">
    <div class="discount-card-mobile d-flex flex-row align-items-center gap-3 px-4">
        <img class="main-icon" src="../../../../../assets/images/{{ iconSource }}.svg" alt="..." />
        <div class="d-flex flex-column flex-fill px-2">
            <span
                [ngClass]="displayDescriptionForMobile || displayCheckboxForMobile ? 'caption-bold' : 'caption-medium'">
                {{ title }}</span>
            <span [innerHTML]="description" class="disclaimer" *ngIf="displayDescriptionForMobile == true"> </span>
            <span class="disclaimer" *ngIf="disabledText">{{ disabledText }}</span>
        </div>

        <div *ngIf="displayCheckbox" style="width: 24px; height: 24px">
            <lib-input-checkbox [(ngModel)]="selected"></lib-input-checkbox>
        </div>
        <div *ngIf="!displayCheckbox" style="width: 16px; height: 64px">
            <ng-container *ngIf="selected; then check; else bar"> </ng-container>
            <ng-template #bar>
                <div class="bar-mark"></div>
            </ng-template>
            <ng-template #check>
                <img class="check-mark" src="../../../../assets/images/discount-check-mark.svg"
                    alt="Desktop card selected checkmark" />
            </ng-template>
        </div>

    </div>
    <div class="d-flex flex-row px-3" *ngIf="eSignFlag">
        <lib-button class="mx-2" (callback)="makeAction(value,'approve')" [disabled]="!selected"  [buttonTheme]="'primary'"
            [buttonSize]="'small'" [buttonText]="'Approve'"></lib-button>
    </div>
</div>