import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AbstractService } from '../abstract/abstract.service';
import { Field } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { CacheData } from 'src/app/core/cacheData';
import { IPage, Page } from 'src/app/core/page';
import { Response } from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class DataStoreService {
  public responseCache = new Array<CacheData>();

  constructor(private web: AbstractService) {}

  /**
   * Get data from the cache or webservice.
   * @param stepName - Page name to get the data for.
   * @param currentPage - true if currently navigated page, false if getting data for other page.
   */
  getPageData(stepName: string, currentPage: boolean): Observable<IPage> {
    const index = this.responseCache.findIndex(
      (p) => p.response.data.name == stepName
    );
    if (index > -1) {
      //invalidate the cache for the current page
      if (currentPage) {
        this.responseCache = this.responseCache.slice(0, index + 1);
      }
      return this.responseCache[index].page$;
    } else if (!environment.production) {
      //return the page for developers
      return this.web.getMockResponse(stepName).pipe(
        map((res) => {
          const ret = new CacheData(res);
          //add to mock cache so we can navigate in mock mode
          this.responseCache = [...this.responseCache, ret];
          return ret;
        }),
        switchMap((ret) => ret.page$)
      );
    }

    throw Error(`${stepName} is not in the store.`);
  }

  /**
   * Used by the first page to get its own data since it wont be in the store
   * @param firstStepName
   * @param keyFactors
   */
  initPageData(
    firstStepName: string,
    keyFactors = 'McGraw#N#Y'
  ): Observable<IPage> {
    const index = this.responseCache.findIndex(
      (p) => p.response.data.name == firstStepName
    );
    if (index > -1) {
      // purge pages past this page if navigating back
      this.responseCache = this.responseCache.slice(0, index + 1);
      return this.responseCache[index].page$;
    }

    const response = new Response();
    response.baseData = {
      expectedContext: '',
      keyFactors,
      randomUUID: '',
    };

    response.data = new Page({ url: '/address', name: 'Address' });
    response.data.fields.push(
      new Field({
        name: 'mainAddress',
        type: 'address',
        id: 'mainAddress',
        caption: 'Address of the property you’d like to insure',
        placeholder: 'Address of the property you’d like to insure',
        helpIcon: true,
        required: true,
      })
    );

    const ret = new CacheData(response);
    this.responseCache.push(ret);
    return ret.page$;
  }

  /**
   * sets data to the service and returns the data for the next page
   * @param page - the current page
   */
  setPageData(page: IPage): Observable<IPage> {
    const index = this.responseCache.findIndex(
      (p) => p.response.data.name == page.name
    );
    if (index > -1) {
      //this.loader.showLoading();
      const cacheData = this.responseCache[index];
      cacheData.prepare(page);
      return this.web.setData(cacheData.response).pipe(
        tap((res) => {
          cacheData.takeSnapshot(page); // reset the snapshot of the page that's leaving
          const index = this.responseCache.findIndex(
            (p) => p.response.data.name == res.data.name
          );
          if (index > -1) {
            let cache = [...this.responseCache];
            cache[index] = new CacheData(res);
            this.responseCache = cache;
          } else {
            this.responseCache = [...this.responseCache, new CacheData(res)];
          }
        }),
        map((res) => res.data),
        finalize(() => {})
      );
    } else {
      throw Error(`${page.name} is not in the store.`);
    }
  }

  /**
   * Gets the previous page data for back navigation
   * @param page
   */
  getPrevPageData(page: IPage): Observable<IPage> {
    let index = this.responseCache.findIndex(
      (p) => p.response.data.name == page.name
    );
    if (index > 0) {
      index = index - 1;
      return this.responseCache[index].page$;
    }
    throw Error(`${page.page} is not in the store.`);
  }

  getBaseData(): any {
    return this.responseCache[this.responseCache.length - 1]?.response.baseData;
  }
}
