import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { PSICComponentsModule } from '@pacificspecialtyinsurancecompany/psic-ng-components';
import { RoutePageComponent } from '../shared/components';
import { SharedModule } from '../shared/shared.module';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { ErrorSystemFailureComponent } from './error-system-failure/error-system-failure.component';
import { OTPAcceptComponent } from './otp/accept-confirmation/accept-confirmation.component';
import { OTPComponent } from './otp/otp.component';
import { OTPSignComponent } from './otp/sign-application/sign-application.component';
import { PagesRoutingModule } from './pages.routes';

@NgModule({
    imports: [ReactiveFormsModule, BrowserModule, FormsModule, PagesRoutingModule, PSICComponentsModule, SharedModule],
    declarations: [
        DocumentsListComponent,
        OTPComponent,
        OTPSignComponent,
        OTPAcceptComponent,
        ErrorSystemFailureComponent,
    ],
    exports: [DocumentsListComponent, OTPComponent],
})
export class PagesModule {}
