<div class="modal-body" *ngIf="isMobileWidth">
    <div
        *ngIf="popupDispalyObj.type == 'actionDialog'"
        [ngClass]="{ 'content-align m-4': popupDispalyObj.download, 'd-flex flex-row m-3': !popupDispalyObj.download }"
    >
        <div class="flex-column">
            <img
                src="{{ popupDispalyObj.imageSrc }}"
                [ngStyle]="{ width: popupDispalyObj.confirm === 'Download' ? '125px' : '54px' }"
                alt="tick-img"
            />
        </div>
        <div class="mt-2 flex-column" [ngClass]="{ 'ms-2': !popupDispalyObj.download }">
            <h6
                class="intro-quote-text"
                [ngClass]="{ 'text-center p-3': popupDispalyObj.download }"
                style="max-width: 325px"
            >
                {{ popupDispalyObj.message }}
            </h6>
            <p class="caption" [ngClass]="{ 'text-center': popupDispalyObj.download }">
                {{ popupDispalyObj.messageExt }}
            </p>
        </div>
    </div>
   

  
    <form [formGroup]="form">
        <div *ngIf="popupDispalyObj.fields.length > 0">
            <div *ngFor="let field of popupDispalyObj.fields" class="d-flex flex-row justify-content-center mt-4">
                <div *ngIf="field.type == 'text' || field.type == 'email' || field.type == 'number'">
                    <lib-input-text [formControlName]="field.name" [field]="field"> </lib-input-text>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer d-md-none d-flex flex-row align-items-center">
    <div *ngIf="popupDispalyObj.cancel !== ''" class="d-flex flex-column flex-fill align-items-start">
        <div class="d-flex">
            <div class="back-img"></div>
            <div
                class="goback-text caption-bold wh-100-color px-2"
                (click)="footerButtonAction(popupDispalyObj.cancel)"
            >
                {{ popupDispalyObj.cancel }}
            </div>
        </div>
    </div>
    <div
        *ngIf="popupDispalyObj.confirm !== '' && popupDispalyObj.summary == true"
        class="d-flex flex-column justify-content-end align-items-center"
    >
        <lib-button
            (click)="footerButtonAction(popupDispalyObj.confirm)"
            [disabled]="!form.valid"
            [buttonTheme]="'tertiary'"
            [buttonSize]="'small'"
            [buttonText]="popupDispalyObj.confirm"
        ></lib-button>
    </div>
</div>

<!-- Desktop -->
<div class="modal-body d-md-inline-flex justify-content-center" *ngIf="!isMobileWidth">
    <div class="content">
        <div *ngIf="popupDispalyObj.type == 'actionDialog'" style="display: contents">
            <img
                class="text-center"
                src="{{ popupDispalyObj.imageSrc }}"
                [ngStyle]="{ width: popupDispalyObj.confirm === 'Download' ? '120px' : '54px' }"
                alt="tick-img"
            />
            <div class="pt-3">
                <h5 class="text-center">{{ popupDispalyObj.message }}</h5>
                <p class="pt-1 caption text-center">{{ popupDispalyObj.messageExt }}</p>
            </div>
        </div>
        <div *ngIf="popupDispalyObj.type == 'paymentScehedule'" class="m-2 ps-2 d-flex flex-row align-items-start">
            <div class="ms-3 flex-column">
                <h5 class="intro-quote-text">{{ popupDispalyObj.message }}</h5>
                <p class="pt-2 alt-bold">{{ popupDispalyObj.messageExt }}</p>

                <ul class="p-alt pt-2">
                    <li *ngFor="let item of popupDispalyObj.fields">
                        <span class="p-alt">
                            <p class="caption">${{ item.due }} due{{ item.date }}</p>
                        </span>
                    </li>
                </ul>
                <p class="alt pt-3">{{ popupDispalyObj.terms }}</p>
            </div>
        </div>
        <form [formGroup]="form">
            <div *ngIf="popupDispalyObj.fields.length > 0" class="d-flex flex-row align-items-center mt-4">
                <div *ngFor="let field of popupDispalyObj.fields">
                    <div
                        *ngIf="field.type == 'text' || field.type == 'email' || field.type == 'number'"
                        class="d-flex me-3"
                        style="width: auto"
                    >
                    
                        <lib-input-text  [formControlName]="field.name" [field]="field"> </lib-input-text>
                    </div>
                </div>
            </div>
        </form>
        <div class="pt-4 mt-2 d-flex flex-row align-items-center">
            <div *ngIf="popupDispalyObj.cancel !== ''">
                <div class="d-flex pe-5">
                    <div class="back-img"></div>
                    <div
                        class="goback-text caption-bold p2-80-color px-2"
                        (click)="footerButtonAction(popupDispalyObj.cancel)"
                    >
                        {{ popupDispalyObj.cancel }}
                    </div>
                </div>
            </div>
            <div class="d-flex align-items-end">
                <lib-button
                    (click)="footerButtonAction(popupDispalyObj.confirm)"
                    [disabled]="!form.valid"
                    [buttonTheme]="'primary'"
                    [buttonSize]="'small'"
                    [buttonText]="popupDispalyObj.confirm"
                ></lib-button>
            </div>
        </div>
    </div>
</div>
