import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-route-page',
    templateUrl: './route-page.component.html',
    styleUrls: ['./route-page.component.scss'],
})
export class RoutePageComponent implements OnInit {
    @Input() goBack: boolean = false;
    @Input() intro = '';
    @Input() introSub = '';
    @Input() premiumSection: boolean = false;
    @Input() introContent = '';
    @Input() introConfirm = '';
    @Input() introTextMaxWidth = '790px';
    @Input() introConfirmMaxWidth = '790px';
    @Input() introSubMaxWidth = '790px';

    @Output() navigatePage = new EventEmitter<any>();
    isMobileWidth: boolean;

    constructor() {}

    ngOnInit(): void {
        // console.log(this);
    }

    pageNavigation(data: any) {
        this.navigatePage.emit(data);
    }

    @HostListener('window:resize', ['$event'])
    getWidthOfScreenSize() {
        const screenWidth = window.innerWidth;
        if (screenWidth <= 576) {
            this.isMobileWidth = true;
        } else {
            this.isMobileWidth = false;
        }
    }
}
