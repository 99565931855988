<ng-template #spinnerTemplate>
    <div class="spinner-background">
        <div class="spinner-border" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</ng-template>

<div class="d-flex justify-content-center text-primary loader" *ngIf="loading">
    <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
</div>
