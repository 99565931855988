import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { SelectableCardComponent } from '../selectable-card/selectable-card.component';

@Component({
    selector: 'app-discount-card',
    templateUrl: './discount-card.component.html',
    styleUrls: ['./discount-card.component.scss'],
})
export class DiscountCardComponent extends SelectableCardComponent {
    @Input()
    get displayCheckboxForMobile(): boolean {
        return this.displayCheckbox;
    }
    set displayCheckboxForMobile(val: boolean) {
        this.displayCheckbox = val;
        if (val) {
            this.minHeight = '96px';
        }
    }
    @Output() performAction = new EventEmitter<{ val: string; type: string }>();
    displayCheckbox: boolean = true;
    @Input() displayDescriptionForMobile: boolean;
    @Input() iconSource: string;
    @Input() description: string;
    @Input() title: string;
    @Input() value: any;
    @Input() disabledText: string;
    @Input() defaultValues: string[];
    @Input() eSignFlag: boolean;

    constructor() {
        super();
    }

    @HostListener('window:resize', ['$event'])
    getWidthOfScreenSize() {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            this.displayCheckbox = true;
        } else {
            this.displayCheckbox = false;
        }
    }

    makeAction(val: any,type:any) {
        this.performAction.emit({val,type});
    }


    ngOnInit(): void {
        const screenWidth = window.innerWidth;
        if (screenWidth < 768) {
            this.displayCheckbox = true;
        } else {
            this.displayCheckbox = false;
        }
        this.defaultValues.forEach((val) => {
            if (val === this.value) {
                this.selected = true;
            }
        });
    }

    onChangeValue(event: any) {
        this.value = event;
    }
}
