<div *ngIf="page$ | async as page" class="vertical-center">
    <app-route-page>
        <div class="d-none d-md-block d-flex justify-content-center desktop-view">
            <div class="d-flex flex-row justify-content-center">
                <div class="d-flex flex-column header-section">
                    <div class="d-flex flex-column header-section" style="width: 588px">
                        <img
                            [src]="page.getField('image')?.icon_path"
                            class="error-page-image"
                            alt="page.getField('image')?.icon_path"
                        />
                    </div>
                </div>
                <div class="d-flex flex-column gap-2 m-4 mt-4 justify-content-center mb-5 content-section">
                    <h1 [innerHTML]="page.getFieldValue('heading')">{{ page.getFieldValue('heading') }}</h1>
                    <p [innerHTML]="page.getFieldValue('subText')">{{ page.getFieldValue('subText') }}</p>

                    <div
                        class="d-flex flex-column align-items-center info-label mt-5 pt-3 mb-3"
                        *ngIf="page.getField('Info-text')"
                    ></div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column d-md-none justify-content-center mobile-view">
            <h3 [innerHTML]="page.getFieldValue('heading')" class="text-center mt-4">
                {{ page.getFieldValue('heading') }}
            </h3>
            <p [innerHTML]="page.getFieldValue('subText')" class="text-center mt-3">
                {{ page.getFieldValue('subText') }}
            </p>
            <div class="mt-5 text-center header-section" style="width: 325px">
                <img
                    [src]="page.getField('image')?.icon_path"
                    class="error-page-image"
                    alt="page.getField('image')?.icon_path"
                />
            </div>
        </div>
    </app-route-page>
</div>
