<div *ngIf="goBack" class="back d-none d-md-none d-md-flex flex-column flex-fill align-items-start">
    <div class="d-flex">
        <div class="back-img"></div>
        <lib-button
            (callback)="pageNavigation('goBack')"
            [disabled]="false"
            [buttonTheme]="'borderless'"
            [buttonSize]="'medium'"
            [buttonText]="'Go back'"
        ></lib-button>
    </div>
</div>

<div style="max-width: 1280px; margin-left: auto; margin-right: auto">
    <div class="route-content justify-content-center mt-md-5" [ngClass]="{ extra: premiumSection }">
        <div class="route-page-container">
            <div class="m-3 d-grid justify-content-center" *ngIf="introContent || intro || introConfirm || introSub">
                <h3 [innerHTML]="introContent" class="text-center mb-2 mobile"></h3>
                <h2 [innerHTML]="introContent" class="text-center mb-2 desktop"></h2>
                <h5 [innerHTML]="intro" class="text-center mobile"></h5>
                <h5 [innerHTML]="introConfirm" class="text-center mobile"></h5>
                <h2 [innerHTML]="intro" class="text-center desktop" [style.maxWidth]="introTextMaxWidth"></h2>
                <h3 [innerHTML]="introConfirm" class="text-center desktop" [style.maxWidth]="introConfirmMaxWidth"></h3>
                <p [innerHTML]="introSub" class="text-center px-2 py-2"></p>
                <!-- Use Bootstrap classes -->
            </div>
            <div class="m-4">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    <div>
        <ng-content select="[footer]"></ng-content>
    </div>
</div>
