import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastService } from '../../services/toast/toast.service';

@Component({
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent implements OnInit {
    @Input() notificationInfo: any;
    @Output() selectedChanged = new EventEmitter<boolean>();
    selected = false;

    constructor(public toastService: ToastService) {}

    remove(index: number) {
        this.toastService.remove(index);
    }

    ngOnInit(): void {
        console.log(this.toastService.toasts);
    }

    selectNotification() {
        this.selectedChanged.emit(!this.selected);
    }
}
